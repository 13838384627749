.home {

    &__hero {
        position: relative;
        &__next {
            position: absolute;
            bottom: 50px;
            color: $white;
            border: 2px solid $white;
            border-radius: 50%;
            width:50px;
            height:50px;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            right: 0;
            margin:auto;

            i {
                font-size: 36px
            }
        }
    }

    &__slider {

        display: none;

        @include mq(md) {
            display: block;
        }

        &.slick-dotted.slick-slider {
            margin-bottom: 0;
        }

        .slick-dots {
            bottom: -50px;

            li button:before {
                color: $gray;
            }

            li.active button:before {
                transform: scale3d(1.1,1.1,1.1);
            }
        }

        &--mobile {
            @include mq(md) {
                display: none;
            }

            margin-bottom: 0 !important;

            .slick-dots {
                bottom: 20px;
            }
        }
    }

    &__slide {
        $s:&;

        height: 90vh;
        overflow: hidden;

        &__video {
            padding-bottom: 60%;

            iframe {
                position: absolute;
                height: 120%!important;
                top: -15%!important;
            }
        }

        &__img {
            height: 0;
            padding-bottom: 56.5%;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                width:100%;
            }
        }

        &--mobile {
            #{$s}__img {
                padding-bottom: 150%;

                img {
                    width: auto;
                    height: 100%;
                    left:50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &__vinos {
        @include padd;

        background: url('/images/home/fondo-blanco.jpg') no-repeat center center / cover;

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 0;


            &__item {
                $i:&;

                flex-basis: 100%;
                @include trans;

                @include mq(sm){
                    flex-basis: 30%;
                }

                &__img {
                    text-align: center;
                }

                &__data {
                    @include trans;
                    transform: translateY(0);
                    text-align: center;
                }

                &__name {
                    font-size: 21px;
                }

                &__actions {
                    @include trans;
                    opacity: 0;
                    transform: translateY(-50px);
                    display: flex;
                    justify-content: space-between;
                    pointer-events: none;

                    ._button {
                        flex-grow: 1;

                        &:first-child{
                            margin-right: 10px;
                            background: $altColor;

                            &:hover {
                                background: $altColor + #333;
                            }
                        }
                    }
                }

                &:hover {
                    padding-bottom: 40px;

                    #{$i}__actions {
                        opacity: 1;
                        pointer-events: all;
                        transform: translateY(0);
                    }

                    #{$i}__data {
                        transform: translateY(-30px);
                    }
                }
            }
        }
    }

    &__historia {

        @include mq(sm) {
            @include padd;
        }

        .row {
            @include flexrow;
        }

        &__img {
            @include img;
        }

        &__data {
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: center;
            max-width: 400px;
            margin: auto;
            align-self: center;

            @include mq(sm){
                padding-top: 0;
                padding-bottom: 0;
            }

            &__text {
                margin-bottom: 20px;
            }
        }
    }

    &__bodega {
        @include padd;

        &__img {
            @include parallax('/images/home/bodega.jpg');

            height: 80vh;
        }

        &__data {
            text-align: center;

            &__text {
                margin-bottom: 40px;
            }
        }
    }

    &__concepto {
        @include padd(bottom);

        &__video {
            @include img;
        }
        &__data {
            text-align: center;
            max-width: 400px;
            margin: auto;
            align-self: center;
            padding-bottom: 40px;

            @include mq(sm){
                padding-bottom: 0;
            }

            &__text {
                margin-bottom: 20px;
            }
        }
    }

    &__pavillion {
        &__video {
            @include img;
        }
        .flexcol {
            background: $lightestGray;
        }
        &__data {
            text-align: center;
            max-width: 400px;
            margin: auto;
            align-self: center;
            padding-top: 40px;

            @include mq(sm){
                padding-top: 0;
            }

            &__text {
                margin-bottom: 20px;
            }
        }
    }

    &__retreat {
        &__image {
            @include img;
        }
        .flexcol {
            background: $lightestGray;
        }
        &__data {
            text-align: center;
            max-width: 400px;
            margin: auto;
            align-self: center;
            padding-top: 40px;
            padding-bottom: 40px;

            @include mq(sm){
                padding-top: 0;
                padding-bottom: 0;
            }

            &__text {
                margin-bottom: 20px;
            }
        }
    }

    &__map {
        &__img {
            @include img();

                img{
                    height: 100%;
                    width: auto;
                    left:50%;
                    transform: translateX(-50%);
                }

            @include mq(sm){
                @include img(25%);

                img {
                    width: 100%;
                    height: auto;
                    left:0;
                    transform: translateX(0);
                }
            }
        }
    }
}
