.press-article {
    $p:&;
    flex-basis: 90%;
    text-align: center;

    @include mq(sm){
        flex-basis: 45%;
    }

    @include mq(md){
        flex-basis: 25%;
    }

    @include mq(lg){
        flex-basis: 22%;
    }

    &--connect {
        @include mq(sm){
            flex-basis: 45%;
        }

        @include mq(md){
            flex-basis: 33%;
        }

        @include mq(lg){
            flex-basis: 30%;
        }

        #{$p}__data {
            &__title {
                font-size: 18px;
            }
            &__text {
                font-size: 18px;
            }
        }

        #{$p}__img {
            width: 100%;
            height: 0;
            padding-bottom: 60%;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left:0;
                width: 100%;
                max-width: none;
                height: auto;
            }
        }
    }

    &__data {
        &__title {
            font-size: 16px;
            padding: 15px 0;
        }
    }

    &__foot {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-top: 1px solid $lightGray;

        &__share {
            display: flex;

            li{
                margin-right: 5px;
                a {
                    color: $midGray;
                }
            }
        }
    }

    &__img {
        img {
            max-width: 200px;
            height: 240px;
        }
    }

    padding-bottom: 40px;
}
