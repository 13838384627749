@mixin label {
    color: $darkestGray;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $altFont;
    font-weight: 700;
}

.tienda {
    @include padd(top);

    &__hero {
        @include padd;

        &__heading {
            ._button {
                @include mq(md){
                    float: right;
                    margin-top: -80px;
                }

                &.-ghost{
                    background: none;
                    border: 1px solid $darkGray;
                    color: $darkGray;
                }
            }
        }

        &__slider {
            .slick-dots {
                bottom: -20px;

                li button:before {
                    color: $gray;
                }

                li.active button:before {
                    transform: scale3d(1.1,1.1,1.1);
                }
            }
        }

        &__slide {
            position: relative;

            &__img {
                @include img(24%);
            }

            &__data {
                text-align: center;
                width: 300px;
                margin: auto;
                padding: 20px 0;

                @include mq(sm) {
                    position: absolute;
                    right: 10%;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                }

                ._button {
                    width: 220px;
                }
            }
        }
    }

    &__listado {

    }

    &__producto {
        width: 300px;
        margin: auto;
        text-align: center;
        margin-bottom: 40px;

        &__img {
            @include img(120%);

            img {
                height: 100%;
                width: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__data {
            height: 260px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        ._button {
            height: 30px;
            line-height: 30px;
        }
    }

    &__connect {
        @include padd;
        text-align: center;

        .section-title {
            h3 {
                font-size: 21px;

                @include mq(sm){
                    font-size: 28px;
                }
            }
        }

        &__texto {
            margin-bottom: 40px;
        }

        ._button {
            width:220px;
        }
    }
}

.producto {
    @include padd(top);

    &__index {
        height: 40px;
        padding: 10px 0 10px;
        border-bottom: 1px solid $lightGray;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(sm) {
            padding: 5px 0 20px;
        }

        li {
            padding: 0 15px;
            position: relative;
            font-family: $altFont;
            font-size:10px;
            letter-spacing: -0.5px;
            text-transform: uppercase;
            font-weight:700;
            display: flex;
            align-items: center;
            line-height: 1;

            @include mq(sm) {
                font-size:14px;
                letter-spacing: auto;
            }

            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    right:0;
                    height: 10px;
                    top: 0px;
                    width: 1px;
                    background: $darkestGray;

                    @include mq(sm) {
                        height: 12px;
                    }
                }
            }
        }
    }

    &__main {
        @include padd;

        &__img {
            @include img(90%);

            img {
                left: 50%;
                height: 100%;
                width: auto;
                transform: translateX(-50%);
            }
        }

        &__data {
            &__nombre {
                font-weight: bold;
                margin-bottom: 20px;
            }

            &__precio {
                font-family: $altFont;
                text-transform: uppercase;
                strong {margin-right: 10px;}
                margin-bottom: 20px;
            }

            &__form {
                margin-bottom: 20px;

                .vikform__input {
                    &--select {
                        flex-basis: 30%;
                    }
                    &:last-child {
                        flex-grow: 1;
                    }
                }

                .vikform__qty {
                    flex-basis: 30%;
                    margin-right: 10px;

                    .vikform__input {
                        width:100%;
                    }
                }

                .vikform__submit {
                    flex-grow: 1;
                }
            }
        }

        &__info {
            &__item {
                margin-bottom: 20px;
            }
        }
    }
}

.carrito,
.checkout {
    @include padd;

    &__heading {
        padding-bottom: 20px;

        &__name {
            @include label;

            margin-bottom: 10px;
        }

        &__title {
            margin: 0 0 20px;
        }

        &__texto {
            font-size: 14px;
        }
    }

    &__items {

        &__total {
            background: $black;
            color: $white;
            height: 40px;
            line-height: 40px;
            margin-bottom: 20px;
            margin-left: 0;
            margin-right: 0;

            &__label {
                @include label;
                color: $white;
            }
        }

        &__actions {
            ._button {
                background: $darkGray;
                width: 100%;
                margin-bottom: 10px;

                @include mq(sm){
                    width: auto;
                }
            }
        }
    }

    &__item {
        $i:&;

        border-bottom: 1px solid $lightGray;
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;

        @include mq(sm){
            padding-top: 10px;
            padding-bottom: 0;
        }

        &__label {
            @include label;
            padding-bottom: 10px;
            white-space: nowrap;

            &--qty {

                @include mq(md){
                    padding-left: 15px;
                }
            }

            &--delete {
                color: $white;
                padding-bottom: 0;
            }

            @include mq(sm){
                padding-bottom: 20px;
            }
        }

        &__value {
            height: 40px;
            line-height: 40px;
            margin: 5px 0;

            span {
                line-height: 18px;
            }

            @include mq(sm){
                margin: 0 0 15px;
            }
        }

        &__delete {
            @include label;
            background: none;
            color: $mainColor;
            border: none;
            height: 40px;
            line-height: 40px;
            margin: 10px 0;

            @include mq(sm){
                margin: 0;
            }

            span {
                &:first-child {
                    font-size: 21px;

                    @include mq(sm) {
                        font-size: 12px;
                    }
                }

                &:last-child {
                    display: none;

                    @include mq(sm) {
                        display: inline-block;
                    }
                }
            }
        }

        &:not(:first-child) {
            .carrito__item__label {
                @include mq(sm) {
                    display:none;
                }
            }
        }

        &:first-child {
            &:before {
                @include mq(sm){
                    content: '';
                    position: absolute;
                    width: 100%;
                    height:1px;
                    background:$lightGray;
                    left:0;
                    right:0;
                    top:35px;
                }
            }
        }
    }

    &__checkout {

        &__title {
            padding-left: 15px;
            padding-right: 15px;

            @include mq(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__continue {
            @include label;
            padding-left: 15px;
            padding-right: 15px;

            @include mq(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__col {
            @include padd(top);
        }

        &__subtitle {
            margin-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;

            @include mq(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__form {
            padding-left: 15px;
            padding-right: 15px;

            @include mq(sm) {
                padding-left: 0;
                padding-right: 0;
            }

            .vikform__input {
                flex-grow: 1;
                margin-bottom: 10px;

                @include mq(sm){
                    margin-bottom: 0;
                }
            }

            &--login {
                .vikform__group {
                    &:last-of-type {
                        justify-content: space-between;
                    }
                }
            }

            &--createaccount {
                .vikform__input,
                .vikform__input--slect {
                    flex-basis:50%;
                }

                .vikform__input--radio + label {
                    margin-top: 10px;
                }

                .vikform__group--password {
                    flex-direction: column;

                    @include mq(sm){
                        width: 50%;
                        align-items: flex-start;
                    }

                    .vikform__input {
                        margin-bottom: 10px;
                    }

                    .vikform__submit {
                        flex-basis: auto;
                    }
                }

                .vikform__group--sub {
                    flex-basis:50%;

                    &:first-child {
                        margin-right: 10px;
                    }

                    .vikform__input--select {
                        padding: 0 10px;
                    }
                }

                .vikform__group {
                    flex-wrap: wrap;

                    @include mq(sm){
                        flex-wrap: nowrap;
                    }
                }
            }
        }
    }
}

.checkout {

    & > .container {
        padding-bottom: 40px;
    }

    &__form {
        .vikform__input {
            flex-grow: 1;
        }

        &--createaccount {
            .vikform__input {
                width: 50%;
                flex-basis:50%;
            }

            .vikform__input--select {
                width: 50%;
                flex-basis:50%;
            }

            .vikform__input--radio + label {
                margin-top: 10px;
            }

            .vikform__group--address {
                flex-wrap: wrap;
                .vikform__group--sub {
                    display: flex;
                    flex-basis: 100%;
                    padding-bottom: 10px;
                }

                .vikform__group--half {
                    flex-basis: 50%;
                }
            }

            .vikform__group--password {
                flex-direction: column;

                @include mq(sm){
                    width: 50%;
                    align-items: flex-start;
                }

                .vikform__input {
                    margin-bottom: 10px;
                }

                .vikform__submit {
                    flex-basis: auto;
                }
            }

            .vikform__group--sub {
                flex-basis:50%;

                &:first-child {
                    margin-right: 10px;
                }

                .vikform__input--select {
                    padding: 0 10px;
                }
            }
        }

        &--payment {
            .vikform__label {
                flex-basis: auto;
                margin-right: 15px;
            }
            .vikform__group--sub {
                display: flex;
            }

            .vikform__group {
                flex-wrap: wrap;

                @include mq(sm){
                    flex-wrap: nowrap;
                }
            }

            .vikform__input {
                margin-bottom: 10px;

                @include mq(sm) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .checkout__items {
        .checkout__items__actions {
            ._button {
                background:$mainColor;
            }
        }
    }
}

.success {
    @include padd;

    .container {
        height: 70vh;
    }

    &__message {
        padding-bottom: 40px;
    }
}
