.reserva {
    &__hero {
        @include padd;
        @include parallax('/images/reserva/reserva1.jpg');
        height: 100vh;
        position: relative;

        color: $white;
        text-align: center;

        .section-title {
            h1 {
                color: $white;
            }
        }

        &__data {
            @include padd;
            padding-top: 15%!important;
        }

        &__text {
            font-size: 18px;
            line-height: 1.9;
        }
    }

    &__enoturismo {
        @include padd;
        text-align: center;

        &__text {
            margin-bottom: 30px;
            color: $midGray;
        }
    }


    &__actividades {
        background: url('/images/home/fondo-blanco.jpg') no-repeat top center / 100%;
        padding-top: 80px;

        .section-title {
            margin-bottom: 40px;

            h2 {
                font-size: 24px;

                @include mq(sm){
                    font-size: 32px;
                }
            }
        }
    }

    &__actividad {

        .flexcol {
            background: $white;
        }

        &__img {
            @include img(70%);
        }
        &__data {
            text-align: center;
            max-width: 400px;
            margin: auto;
            align-self: center;
            padding-top: 40px;
            padding-bottom: 40px;

            @include mq(sm){
                padding-top: 0;
                padding-bottom: 0;
            }

            &__text {
                margin-bottom: 20px;
                font-size: 18px;
            }
        }

        .section-title {
            h3 {
                font-size: 21px;

                @include mq(sm){
                    font-size: 34px;
                }
            }
        }
    }

    &__info {
        @include padd;
        text-align: center;
    }
}
