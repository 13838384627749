$white: #fff;
$black: #000;
$corpBlack: #3e372c;
$darkestGray: #262626;
$darkGray: #787871;
$midGray: #877D73;
$lightGray: #ebebeb;
$lightestGray: #f4f7f8;
$red: #af1f24;
$vikGray: #3f3f3f;

$mainColor: $red;
$altColor: $vikGray;
$link: $mainColor;
$linkHover: $mainColor + #333;
$text: $white;
$textDark: $black;
$accent: $mainColor;
$bg: $white;
$bgSecondary: $mainColor;
$bgDark: $black;

$base: 50px;
$radius: 20px;

$head: 60px;
$xxl: 36px;
$xl: 24px;
$lg: 21px;
$md: 18px;
$sm: 16px;
$xs: 14px;
$xxs: 12px;

$duration: 0.3s;
$easing: cubic-bezier(.785, .135, .15, .86);
$easingLight: ease;

$mainFont: 'Volkorn', serif;
$altFont: 'Montserrat', sans-serif;
$titleFont: 'Trajan Pro', serif;
