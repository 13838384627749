.header {

    $h: &;

    @include trans;

    position: fixed;
    top:0;
    left:0;
    right:0;
    height: 60px;
    z-index: 1000;
    text-align: center;
    background: rgba(255,255,255,.3);
    border-bottom: 1px solid rgba(150,150,150,.3);

    @include mq(md){
        background: transparentize($white, .2);
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 20px;
        top: 20px;

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md){
            display: none;
        }
    }

    &__container {
        position: relative;
    }

    &__logo {
        @include trans;
        position: absolute;
        left:10px;
        top: 10px;

        @include mq(md){
            left:0;
        }

        img {
            @include trans;
            width:40px;
        }
    }

    &__nav {
        @include trans;
        display: flex;
        justify-content: center;
        top: 60px;
        left: 0;
        transform: translateX(-110%);
        background: transparentize($mainColor - #444, .3);
        position: fixed;
        width: 100%;
        padding-bottom: 50px;

        @include mq(md){
            position: relative;
            transform: translateX(0);
            background: transparent;
            top: 20px;
            padding-bottom: 0;
        }
    }

    &__menu {
        width: 100%;

        @include mq(md){
            display: flex;
            justify-content: center;
        }

        &> li {
            position: relative;
            border-bottom: 1px solid $white;


            @include mq(md){
                border-bottom: none;
                padding-bottom: 10px;
            }

            &:hover {
                & > a + #{$h}__submenu {

                    max-height: 50vh;

                    @include mq(md){
                        opacity: 1;
                        transform: translateY(20px) translateX(-50%);
                        pointer-events: all;

                    }
                }
            }

            &.-active {
                & > a{
                    color: $mainColor;
                }
            }
        }

        a {
            color:$white;
            display: block;
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
            font-size:18px;
            text-decoration: none;
            font-family: $altFont;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;

            @include mq(md){
                color:$darkestGray;
                font-size:14px;
                height: auto;
                line-height: 1;
            }

            &:hover {
                color: $mainColor;
            }
        }

        &__showmore {
            height: 40px;
            width: 40px;
            position: absolute;
            right:0;
            color: $white;
            border-left: 1px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;

            @include mq(md) {
                display: none;
            }

            i {
                @include trans;
            }

            &:hover {
                & + a + #{$h}__submenu {
                    max-height: 50vh;

                    @include mq(md){
                        opacity: 1;
                        transform: translateY(20px) translateX(-50%);
                        pointer-events: all;
                    }
                }

                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__submenu {
        @include trans;

        border-top: 1px solid $white;
        max-height: 0;
        overflow: hidden;
        position: relative;

        @include mq(md){
            border-top: none;
            position: absolute;
            top: 0;
            width: 250px;
            opacity: 0;
            pointer-events: none;
            transform: translateY(20px) translateX(-50%);
            left: 50%;
        }

        a {
            @include mq(md){
                padding: 5px 0;
            }
        }
    }

    &__tienda {
        display: flex;
        position: absolute;
        bottom: 15px;
        right:0;
        font-size: 14px;
        left:0;
        width:100px;
        margin: auto;

        @include mq(md){
            top: -2px;
            bottom: auto;
            right:0;
            left: auto;
        }

        a {
            color: $white;
            font-family: $altFont;
            font-weight: bold;
            text-transform: uppercase;

            @include mq(md){
                color: $darkestGray;
            }
        }

        &__cart {
            padding-left: 15px;
            span {
                color: $white;
                font-family: $altFont;
                font-weight: bold;
                text-transform: uppercase;

                @include mq(md){
                    color: $darkestGray;
                }
            }
            img {
                margin-left: 10px;

                &._white {
                    @include mq(md){
                        display: none;
                    }
                }

                &._black {
                    display: none;
                    @include mq(md){
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.-ontop {
        @include mq(md){
            height: 120px;
            background: transparent;

            #{$h}__logo {
                top: 20px;

                img {
                    width:70px;
                }
            }

            #{$h}__nav {
                top:40px;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

.-home {
    .header {
        background: transparentize($darkestGray, .2);
        a {
            color: $white;
        }

        @include mq(md){
            border-bottom: none;
        }

        &__tienda {
            &__cart {
                span {
                    color: $white;
                }
                @include mq(md){
                    ._black {
                        display: none;
                    }

                    ._white {
                        display: inline-block;
                    }
                }
            }
        }

        &.-ontop {
            @include mq(md  ){
                background: transparent;
            }
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
