.share {
    display: flex;
    justify-content: center;
    margin: 40px auto;

    li {
        position: relative;
        margin: 0 7px;

        &:before {
            content:'';
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            transform: rotate(45deg);
            border: 1px solid transparentize($midGray, .5);
            pointer-events: none;
        }
    }

    a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $midGray;
    }
}
