.section-title {
    $s:&;

    text-align: center;
    margin-bottom: 20px;

    &__text {
        margin: 0;
        padding: 0 0 10px;
        font-family: $titleFont;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__alt {
        color: $mainColor;
        text-transform: uppercase;
        font-family: $altFont;
        font-weight: 700;
        font-size: 14px;
        display: block;
        padding-top: 20px;
    }

    h1 {
        font-size: 36px;

        @include mq(sm) {
            font-size: 48px;
        }
    }

    h2 {
        font-size: 32px;

        @include mq(sm) {
            font-size: 44px;
        }
    }

    h3 {
        font-size: 28px;

        @include mq(sm) {
            font-size: 40px;
        }
    }

    &--alt {
        #{$s}__text {
            padding-top: 10px;
        }
    }
}
