.vikform{

    &__group {
        display: flex;
        padding-bottom: 10px;
        position: relative;
    }

    &__input {
        outline:none;
        background: $white;
        font-size: 14px;
        padding: 0 20px;
        height: 40px;
        border: 1px solid $lightGray - #111;
        color:$darkestGray;
        width: auto;
        min-width: none;

        &:not(:last-child) {
            @include mq(sm){
                margin-right: 10px;
            }
        }

        &--number {
             -moz-appearance: textfield;
             text-align: center;
             width: 100%;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -moz-appearance: textfield;
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &::placeholder{
            opacity:.7;
        }

        &--radio {
            position: absolute;
            left: -9000px;
            opacity: 0;
            visibility: hidden;

            & + label {
                padding-left: 30px;
                position: relative;
                cursor: pointer;

                &:before {
                    content: '';
                    border: 1px solid $darkestGray;
                    width:20px;
                    height: 20px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                }

                &:after {
                    @include trans;
                    content: '';
                    width:12px;
                    height: 12px;
                    border-radius: 50%;
                    position: absolute;
                    left: 4px;
                    top:4px;
                    background: $darkestGray;
                    opacity: 0;
                }
            }

            &:checked {
                & + label {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__link {
        color: $midGray;
        text-decoration: underline;
        font-size: 14px;
    }


    &__qty {
        position: relative;
        max-width: 100px;

        .quantity-nav {
            position: absolute;
            top:0;
            left: 0;
            right:0;
        }

        .quantity-button {
          position: absolute;
          cursor: pointer;
          width: 20px;
          text-align: center;
          color: $darkestGray;
          font-size: 16px;
          font-family: $altFont;
          line-height: 40px;
          height: 40px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none;
          top: 0;
          font-weight: 700;
        }

        .quantity-button.quantity-up {
          right: 0;
        }

        .quantity-button.quantity-down {
          left:0;
        }
    }

    &__submit {
        background: $mainColor;
        height: 40px;
        line-height: 40px;
        border: none;
        font-family: $altFont;
        color: $white;
        text-transform: uppercase;
        font-size: 12px;
        padding: 0 30px;
        font-weight: 700;
    }

    &__paypal {
        width: 200px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        text-align: center;
        background: #009cde;
    }
}
